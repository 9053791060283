
const Layout = () => import('@/layout/index.vue')

const Login = () => import('@/views/login')

const ShareDetails = () => import('@views/share-details')

const NotFound = () => import('@/views/exception/404')

const FileList = () => import('@views/file-list')
const Album = () => import('@views/album')
const Favor = () => import('@views/favor')
const Share = () => import('@views/share')
const Bin = () => import('@views/bin')
const CoShare = () => import('@views/co-share')
const UserSettings = () => import('@views/profile/settings')

const RouteView = {
  render: (h) => h('router-view')
}

export const asyncRouterMap = [

]


export const constantRouterMap = [
  {
    path: '/',
    redirect: '/file-list'
  },
  {
    name: 'Login',
    path: '/login',
    component: Login,
    meta: {
      addBreadcrumb: false,
      module: 'Login page'
    }
  },
  {
    name: 'ShareDetails',
    path: '/share-details',
    component: RouteView,
    children: [
      {
        path: '/share-details/:shareName/:folder?/:parentId*',
        component: ShareDetails,
        meta: {
          notRequireLogin: true,
          module: 'File sharing',
          defineResponseInterceptor: true
        }
      }
    ]
  },
  {
    path: '/file-list',
    component: Layout,
    redirect: '/file-list/0',
    meta: {
      title: 'Document',
      addBreadcrumb: true,
      hasSubMenu: false,
      icon: 'file'
    },
    children: [
      {
        name: 'FileList',
        path: '/file-list/:parentId',
        component: FileList,
        meta: {
          module: 'Document'
        }
      }
    ]
  },
  {
    name: 'Favor',
    path: '/favor',
    component: Layout,
    redirect: '/favor',
    meta: {
      title: 'Favorites',
      addBreadcrumb: true,
      hasSubMenu: false,
      icon: 'database'
    },
    children: [
      {
        path: '/favor',
        component: Favor,
        meta: {
          module: 'Collect'
        }
      }
    ]
  },
  {
    name: 'RecycleBin',
    path: '/recycle-bin',
    component: Layout,
    redirect: '/recycle-bin',
    meta: {
      title: 'Recycle bin',
      addBreadcrumb: true,
      hasSubMenu: false,
      icon: 'rest'
    },
    children: [
      {
        path: '/recycle-bin',
        component: Bin,
        meta: {
          module: 'Recycle bin'
        }
      }
    ]
  },
  {
    name: 'NotFound',
    path: '/404',
    component: NotFound
  },
  {
    path: '*',
    redirect: '/404'
  }
]



export default constantRouterMap
