import request from '@/service'

const prefix = '/api'

const UserAPI = {
  LoginAPI: prefix + '/user/login',
  RegisterAPI: prefix + '/user/register',
  UserExistCheckAPI: prefix + '/user/register/n/',
  UploadAvatarAPI: prefix + '/user/profile/',
  GetUserInfoAPI: prefix + '/user/'
}

export const appLoginRequest = (payload, isRemeber = false) => {
  return request.post({
    url: !isRemeber ? UserAPI.LoginAPI + '/0' : UserAPI.LoginAPI + '/1',
    data: payload
  })
}

export const appRegisterRequest = (payload) => {
  return request.post({
    url: UserAPI.RegisterAPI,
    data: payload
  })
}

export const checkUserExistRequest = (payload) => {
  return request.get({
    url: UserAPI.UserExistCheckAPI + payload.userName
  })
}

export const uploadUserAvatarRequest = (payload) => {
  const formData = new FormData()
  formData.append('file', payload.file)
  return request.post({
    url: UserAPI.UploadAvatarAPI,
    data: formData
  })
}

export const getUserInfoRequest = (payload) => {
  return request.get({
    url: UserAPI.GetUserInfoAPI + payload.username
  })
}

export default {
  appLoginRequest,
  appRegisterRequest,
  checkUserExistRequest,
  uploadUserAvatarRequest,
  getUserInfoRequest
}